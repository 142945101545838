import {Component, OnInit} from '@angular/core';

import { CommonModule, NgFor } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { AccordionModule } from 'primeng/accordion';
import { StepsModule } from 'primeng/steps';
import { ScrollerModule } from 'primeng/scroller';
import { MessageService } from 'primeng/api';

import { ToastModule } from 'primeng/toast';
import { ActivatedRoute, Router } from '@angular/router';

import { PanelModule } from 'primeng/panel';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import {
  Category,
  ClientService,
  CreateOrderDto,
  DefaultService,
  Product,
  Shop
} from '../../../../../shared-lib/src/lib/api';
import { HttpClientModule } from '@angular/common/http';
import { Order } from '../../../../../shared-lib/src/lib/api/model/order';
import { SharedLibService } from '../../../../../shared-lib/src/public-api';
import {StepperModule} from "primeng/stepper";
import {FormBuilder, FormGroup, FormsModule, Validators} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import { DropdownModule } from 'primeng/dropdown';
import {CalendarModule} from "primeng/calendar";
import {OrderListComponent} from "./order-list/order-list.component";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {CheckboxModule} from "primeng/checkbox";


interface CategoryWithProducts extends Category {
  products: any[];
}


@Component({
  selector: 'app-product-search',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CardModule,
    AccordionModule,
    StepsModule,
    ScrollerModule,
    ToastModule,
    PanelModule,
    DividerModule,
    DialogModule,
    HttpClientModule,
    StepperModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    CalendarModule,
    OrderListComponent,
    ProgressSpinnerModule,
    CheckboxModule
  ],
  providers: [MessageService, DefaultService, SharedLibService],
  templateUrl: './product-search.component.html',
  styleUrl: './product-search.component.scss',
})
export class ProductSearchComponent implements OnInit {

  categories: CategoryWithProducts[] = [];
  products: Product[] = [];

  searchTerm: string = '';
  filteredProducts: any[] = [];

  order: Product[] = [];

  loading = true;

  showOrderDialog: boolean = false;
  selectedShopId = "not set";
  selectedShopName = "Produkte"

  // order data
  shopPickupLocations = []
  orderData = {
    firstName: "",
    lastName: "",
    pickupLocation: "",
    pickupDate: new Date()
  }
  savingOrderSpinner = false;

  constructor(private messageService: MessageService, private clientService: ClientService, private route: ActivatedRoute, private sharedLibService: SharedLibService ) {}


  ngOnInit() {
    // get shopid from url
    this.route.params.subscribe((params) => {
      this.selectedShopId = params['shopId'];

      this.clientService.clientControllerFindShopById(this.selectedShopId).subscribe((shop: Shop) => {
        console.log(shop)
        this.selectedShopName = shop.name;
      })

    });

   this.clientService.clientControllerFindSettingsByShopId(this.selectedShopId).subscribe((settings: any ) => {
     console.log(settings);
     this.shopPickupLocations = settings[0].data.pickupLocations
     console.log(this.shopPickupLocations);
    })


    this.clientService.clientControllerFindAllCategoriesByShopId(this.selectedShopId).subscribe((response: Category[]) => {
      this.categories = response.map((category) => ({ ...category, products: [] }));

      this.categories.forEach((category) => {
        this.clientService.clientControllerFindAllProductsByCategoryId(category.id).subscribe((response: any) => {
          this.loading = false;
          category.products = response;
        });
      });
    }
    );
  }

  addToOrder(product: any): void {
    this.order.push(product);
    this.showNotification('Produkt hinzugefügt!');
  }

  showNotification(message: string): void {
    // show bottom center
    this.messageService.add({ severity: 'success', summary: 'Success', detail: message });
  }

  getOrderSummary(): any[] {
    const orderSummary = [];
    const groupedOrder = this.groupBy(this.order, 'name');

    for (const productName in groupedOrder) {
      if (groupedOrder.hasOwnProperty(productName)) {
        const quantity = groupedOrder[productName].length;
        const product = groupedOrder[productName][0]; // Assuming products with the same name have the same details
        orderSummary.push({ quantity, product });
      }
    }

    return orderSummary;
  }

  groupBy(array: any[], key: string): any {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  }


  onSearch(term: string) {
    this.searchTerm = term;
    this.filteredProducts = this.categories
      .map(category => ({
        name: category.name,
        products: category.products.filter(product => product.name.toLowerCase().includes(term.toLowerCase()))
      }))
      .filter(category => category.products.length > 0);
  }

  calcGrossPrice(netprice: number, tax: number) {
   return this.sharedLibService.calcGrossPrice(netprice, tax);
  }

  saveOrder(): void {
    this.savingOrderSpinner = true;
    this.orderData.pickupDate.setDate(this.orderData.pickupDate.getDate() + 1) //Add one day => needed

    // create order
    const newOrder: CreateOrderDto = {
      shopid: this.selectedShopId,
      customerfirstname: this.orderData.firstName,
      customerlastname: this.orderData.lastName,
      pickuplocation: this.orderData.pickupLocation,
      pickupdate: this.orderData.pickupDate.toUTCString()
    };


    this.clientService.clientControllerCreateOrder(newOrder).subscribe((response: Order) => {
      console.log(response);

      // only send product ids
      this.clientService.clientControllerCreateOrderList(response.id, this.order.map((product) => product.id)).subscribe( (response: any) => {
        console.log(response)

        this.showOrderDialog = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Bestellung war erfolgreich!' });
        this.order = [];

        this.savingOrderSpinner = false;
      }
      );
    });
  }

  openOrderDialog(){
    this.showOrderDialog = true;
  }

  protected readonly Object = Object;
  termsAccepted = false;
}
