import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import {environment} from "../../fastro-efe/src/environments/environment";
import {enableProdMode} from "@angular/core";

console.log("isProduction: " + environment.production)
if(environment.production) {
    enableProdMode();
}
bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
