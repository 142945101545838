<h1>Local shops</h1>
<div>
    <!--div>
        <span> 
            <span class="p-input-icon-left p-float-label">
                <i class="pi pi-search"></i>
                <input  id="zipCode" type="text" pInputText/>
                <label for="zipCode">PLZ</label>
            </span>
    
            <button pButton  class="p-button-primary" type="button" label="Search" (click)="searchShops()"  ></button>
         </span>
    </div-->

    @if (loading) {
        <p-progressSpinner class="center"  ariaLabel="loading" />
    } @else {
        @for (shop of shops; track $index) {
            <div class="border inline">
                <h3>{{ shop.name }}</h3>
                <p-button [routerLink]="['/shops', shop.id, 'products']" class="p-button-rounded p-button-text vertical-center" icon="pi pi-arrow-up-right" ></p-button>
            </div>
        }
        @empty {
            <h3 class="center">No Shops</h3>
        }
    }



</div>
