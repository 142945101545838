import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { Shop } from '../../../../../shared-lib/src/lib/api/model/shop';
import { ClientService, DefaultService } from '../../../../../shared-lib/src/lib/api';
import { HttpClientModule } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-shops',
  standalone: true,
  imports: [CommonModule, RouterLink, InputTextModule, ButtonModule, CardModule, HttpClientModule, ProgressSpinnerModule ],
  providers: [DefaultService],
  templateUrl: './shops.component.html',
  styleUrl: './shops.component.scss'
})
export class ShopsComponent implements OnInit {
  public zipCode: string = '';
  public shops: Shop[] =  [];
  loading = true;

  constructor(private clientService: ClientService) { }
  
  ngOnInit(): void {
    this.clientService.clientControllerFindAllShops().subscribe((response: any) => {
      console.log(response);
      this.loading = false;
      this.shops = response;
    });
  }

  searchShops() {
    // TODO Search shops in range of zip code
  }
}
