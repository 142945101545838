import {Component, Input} from '@angular/core';
import {CurrencyPipe} from "@angular/common";
import {SharedLibService} from "../../../../../../shared-lib/src/lib/shared-lib.service";

@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [
    CurrencyPipe
  ],
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.scss'
})
export class OrderListComponent {

  @Input() order: any;
  @Input() orderItems: any[] = []


  constructor(private sharedLibService:SharedLibService) {


  }

  getTotalPrice(): number {
    return this.order.reduce((total: number, orderItem: any) => total + this.calcGrossPrice(orderItem.netprice, orderItem.tax), 0);
  }

  calcGrossPrice(netprice: number, tax: number) {
    return this.sharedLibService.calcGrossPrice(netprice, tax);
  }
}
