import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApiModule } from '../../../shared-lib/src/lib/api';
import { apiConfigFactory } from '../../../shared-lib/src/public-api';
 
 

export const appConfig: ApplicationConfig = {
  providers: [
     provideRouter(routes),
     provideAnimationsAsync(),
     provideHttpClient(),
     importProvidersFrom([ApiModule.forRoot(apiConfigFactory)]),
  ]
};
