@if (order.length === 0) {
    Der Warenkorb ist leer!
} @else {
    @for (orderItem of orderItems; track $index) {

        <div class="inline">
            <span> {{ orderItem.quantity }} x </span>
            <span> {{ orderItem.product.name }} </span>
            <span> {{ calcGrossPrice(orderItem.product.netprice, orderItem.product.tax) * orderItem.quantity | currency: 'EUR' }}</span>
        </div>
   }
    <div class="inline bold">
        <span> Summe: </span>
        <div>{{ getTotalPrice() | currency: 'EUR' }}</div>
    </div>

}