import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
  import { ProductSearchComponent } from './components/product-search/product-search.component';
 
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ProductSearchComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'fastro-cfe';
}
