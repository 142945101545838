import { Routes } from '@angular/router';
import { ShopsComponent } from './pages/shops/shops.component';
import { ProductSearchComponent } from './components/product-search/product-search.component';
 
export const routes: Routes = [

    {path: 'shops',  component: ShopsComponent},
    { path: 'shops/:shopId/products',  component: ProductSearchComponent}, 
    
    { path: '', redirectTo: 'shops', pathMatch: 'full' },

];
