<div class="header-container">
    <h2>{{ selectedShopName }}</h2>
    <p-button type="button" label="Bestellung" icon="pi pi-shopping-cart" class="p-button-primary"
              (click)="openOrderDialog()" badge="{{ order.length }}"></p-button>
</div>

<!---dialog is hidden as long as order is empty-->
<div class="card flex justify-content-center">
    <p-dialog [modal]="true" header="Bestellübersicht" [(visible)]="showOrderDialog" [style]="{width: '90vw'}">

        @if (showOrderDialog) {
            @if (savingOrderSpinner) {
                <p-progressSpinner class="middle" ariaLabel="loading" />
            }
            @else {
            <div class="card">
                <p-stepper orientation="vertical">
                    <p-stepperPanel header="Bestellung">
                        <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
                            <div class="flex flex-column h-12rem">
                                <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                                    <app-order-list [order]="order" [orderItems]="getOrderSummary()"></app-order-list>
                                </div>
                            </div>
                            <div class="flex py-4">
                                <p-button size="small" [disabled]="order.length === 0" label="Weiter" (onClick)="nextCallback.emit()"/>
                            </div>
                        </ng-template>
                    </p-stepperPanel>
                    <p-stepperPanel header="Kunde">
                        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                                     let-index="index">
                            <div class="flex flex-column h-12rem">
                                <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                                    <div>
                                        <div>
                                            <input [(ngModel)]="orderData.firstName" id="firstName" type="text"
                                                   placeholder="Vorname" pInputText class="w-full mb-3">
                                        </div>
                                        <div>
                                            <input [(ngModel)]="orderData.lastName" id="lastName" type="text"
                                                   placeholder="Nachname" pInputText class="w-full mb-3">
                                        </div>
                                        <div>
                                            <p>Abholort:</p>
                                            <p-dropdown
                                                    [options]="shopPickupLocations"
                                                    [(ngModel)]="orderData.pickupLocation"
                                                    placeholder="Abholort wählen"/>
                                        </div>
                                        <div>
                                            <p>Abholdatum:</p>
                                            <p-calendar
                                                    [(ngModel)]="orderData.pickupDate"
                                                    [touchUI]="true"
                                                    [showIcon]="true"
                                                    appendTo="body"
                                                    [readonlyInput]="true"
                                                    dateFormat="dd.mm.yy"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="flex py-4 gap-2">
                                    <p-button size="small" label="Zurück" severity="secondary" (onClick)="prevCallback.emit()"/>
                                    <p-button [disabled]="!orderData.firstName || !orderData.lastName  || !orderData.pickupLocation || order.length === 0" size="small" label="Weiter" (onClick)="nextCallback.emit()"/>
                                </div>
                            </div>
                        </ng-template>
                    </p-stepperPanel>
                    <p-stepperPanel header="Zusammenfassung">
                        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
                            <div class="flex flex-column h-12rem">
                                <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                                    <h4>Kunden Informationen:</h4>
                                    <p>Vorname: {{orderData.firstName}}</p>
                                    <p>Nachname: {{orderData.lastName}}</p>
                                    <p>Abholort: {{orderData.pickupLocation}}</p>
                                    <p>Abholtag: {{orderData.pickupDate.toLocaleDateString()}}</p>

                                    <h4>Bestell Informationen:</h4>
                                    <app-order-list [order]="order" [orderItems]="getOrderSummary()"></app-order-list>


                                </div>
                            </div>
                            <p-divider />
                            <p-checkbox class="mt-1" [(ngModel)]="termsAccepted" [binary]="true" label="Ich stimme hiermit der Verarbeitung meiner Daten zu." id="isShopOwner"></p-checkbox>
                            <p-divider />
                            <div>
                                <div class="flex py-4">
                                    <p-button size="small"  severity="secondary" label="Zurück" (onClick)="prevCallback.emit()"/>
                                    <p-button size="small" [disabled]="!orderData.firstName || !orderData.lastName  || !orderData.pickupLocation || !termsAccepted || order.length === 0" type="button" label="Verbindlich Bestellen" class="p-button-primary" (click)="saveOrder()"></p-button >
                                </div>
                            </div>

                        </ng-template>
                    </p-stepperPanel>
                </p-stepper>
            </div>
            }
        }
    </p-dialog>
</div>


<!-- product list / selection -->
@if (loading) {
    <p-progressSpinner class="center" ariaLabel="loading" />
} @else {
    <p-accordion [multiple]="true">
        @for (category of categories; track $index) {
            <p-accordionTab header="{{ category.name }}" [selected]="true">
                @if (category.products.length > 0) {
                    <div class="p-grid">
                        @for (product of category.products; track $index) {
                            <div class="product-item">
                                <div class="name-description">
                                    <div class="name">{{ product.name }}</div>
                                    <div class="description">{{ product.description }}</div>
                                </div>
                                <div class="price">{{ calcGrossPrice(product.netprice, product.tax) | currency: 'EUR' }}</div>
                                <div class="add-to-order">
                                    <p-button class="float-right" icon="pi pi-plus"
                                              (click)="addToOrder(product)"></p-button>
                                </div>
                            </div>

                            @if ($index != category.products.length - 1) {
                                <p-divider></p-divider>
                            }
                        }
                    </div>

                } @else {
                    No products available in this category.
                }
            </p-accordionTab>
        }
    </p-accordion>
}



<p-toast position="bottom-center"></p-toast>
  

 